import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/refund.md"

export default function RefundPage() {
  return (
    <LegalLayout
      description="Bad refund policies are infuriating. We never want our customers to feel that way, so our refund policy is simple: If you're ever unhappy with our product for any reason, we'll take care of you."
      heading="A fair refund policy."
      source={source}
      title="Refund Policy"
    />
  )
}
